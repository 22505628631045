import React from 'react';

export enum Authority {
	ASSIGN_MAIDS = 'ASSIGN_MAIDS',
	DELETE_NOTE = 'DELETE_NOTE',
	EDIT_CHECKOUT_DATE = 'EDIT_CHECKOUT_DATE',
	EDIT_ROOMS = 'EDIT_ROOMS',
	EDIT_TENANT = 'EDIT_TENANT',
	EDIT_USER_LIST = 'EDIT_USER_LIST',
	EDIT_TECH_CATEGORIES = 'EDIT_TECH_CATEGORIES',
	IMPERSONATE = 'IMPERSONATE',
	SEND_INVITATION = 'SEND_INVITATION',
	SEND_NOTE = 'SEND_NOTE',
	SETUP_APP = 'SETUP_APP',
	SETUP_TENANT = 'SETUP_TENANT',
	SUPER_ADMIN = 'SUPER_ADMIN',
	SUPER_EDIT_USER_LIST = 'SUPER_EDIT_USER_LIST',
	TRIGGER_NIGHTLY_PROCESS = 'TRIGGER_NIGHTLY_PROCESS',
	VIEW_EXPEDITED_ITEMS_LIST = 'VIEW_EXPEDITED_ITEMS_LIST',
	VIEW_GRID = 'VIEW_GRID',
	VIEW_MACHINE_VIEW = 'VIEW_MACHINE_VIEW',
	VIEW_NOTE = 'VIEW_NOTE',
	VIEW_STATUS_CHANGE_LOG = 'VIEW_STATUS_CHANGE_LOG',
	VIEW_USER_LIST = 'VIEW_USER_LIST',
	VIEW_USER_QR_CODE = 'VIEW_USER_QR_CODE',
	VIEW_TECH_DEFECT = 'VIEW_TECH_DEFECT',
	ADD_TECH_DEFECT = 'ADD_TECH_DEFECT',
	EDIT_TECH_DEFECT_STATUS = 'EDIT_TECH_DEFECT_STATUS',
	VIEW_TECH_VIEW_WITHOUT_FEATURE = 'VIEW_TECH_VIEW_WITHOUT_FEATURE',
	EDIT_GREEN_CHOICE = 'EDIT_GREEN_CHOICE',
	EDIT_GREEN_CHOICE_SETTINGS = 'EDIT_GREEN_CHOICE_SETTINGS',
	FILTER_SECTION_VIEW = 'FILTER_SECTION_VIEW',
}

export enum ButtonType {
	LEFT = 'LEFT',
	RIGHT = 'RIGHT',
	SINGLE = 'SINGLE',
	NONE = 'NONE',
}

export enum RoleName {
	ASSIGNER = 'ASSIGNER',
	HOUSEKEEPER = 'HOUSEKEEPER',
	MAID = 'MAID',
	RECEPTION = 'RECEPTION',
	SUPER_ADMIN = 'SUPER_ADMIN',
	TENANT_ADMIN = 'TENANT_ADMIN',
	USER_ADMIN = 'USER_ADMIN',
	TECH_ADMIN = 'TECH_ADMIN',
	TECHNICIAN = 'TECHNICIAN',
	REPORTER = 'REPORTER',
	HEAD_CLEANER = 'HEAD_CLEANER',
	CLEAN_WITHOUT_CHECK = 'CLEAN_WITHOUT_CHECK',
}

export enum QrAutogenInterval {
	NONE = 'NONE',
	DAILY = 'DAILY',
	WEEKLY = 'WEEKLY',
	MONTHLY = 'MONTHLY',
}

export enum SectionItemButtonsMode {
	SINGLE,
	DOUBLE,
}

export enum UserAdminModalMode {
	NONE = 'NONE',
	ADD_USER_MODAL = 'ADD_USER_MODAL',
	ADD_MAID_MODAL = 'ADD_MAID_MODAL',
	EDIT_USER = 'EDIT_USER',
}

export enum VendorId {
	APALEO = 'APALEO',
	OHIP = 'OHIP',
	HS3 = 'HS3',
	NONE = 'NONE',
	ERROR = 'ERROR',
}

export enum VendorStatus {
	INIT = 'INIT',
	ACCESS_GRANTED = 'ACCESS_GRANTED',
	CONNECTED = 'CONNECTED',
}

export enum LongStayMode {
	DURATION = 'DURATION',
	RATE_PLAN = 'RATE_PLAN',
	ALWAYS = 'ALWAYS'
}

export enum PmsPageState {
	OVERVIEW = 'OVERVIEW',
	LONGSTAY = 'LONGSTAY',
}

export enum GenericPageState {
	DELETE = 'DELETE',
	EDIT = 'EDIT',
	ERROR = 'ERROR',
	LOADING = 'LOADING',
	DISPLAY = 'DISPLAY',
	SETTINGS = 'SETTINGS',
	DISABLED = 'DISABLED',
}

export enum SaveState {
	NONE = 'NONE',
	SAVING = 'SAVING',
	SAVED = 'SAVED',
	UNSAVED = 'UNSAVED',
	ERROR = 'ERROR',
}

export enum RoomDetailsView {
	GENERAL = 'GENERAL',
	TECHNICIAN = 'TECHNICIAN',
	OVERVIEW = 'OVERVIEW',
	REPORT_DEFECT = 'REPORT_DEFECT',
	LONGSTAY = 'LONGSTAY',
	LONGSTAY_SUCCESS = 'LONGSTAY_SUCCESS',
}

export enum IssueType {
	TASK = 'TASK',
	DEFECT = 'DEFECT',
}

export enum PostponeMode {
	ADJUST_INTERVAL = 'ADJUST_INTERVAL',
	POSTPONE_ONCE = 'POSTPONE_ONCE',
}

export enum IssueStatus {
	OPEN = 'OPEN',
	IN_PROGRESS = 'IN_PROGRESS',
	TO_BE_VERIFIED = 'TO_BE_VERIFIED',
	DONE = 'DONE',
}

export enum PasswordComponentMode {
	RESET_PASSWORD = 'RESET_PASSWORD',
	CHANGE_PASSWORD = 'CHANGE_PASSWORD',
}

export enum DatePickerRange {
	MONTH = 'MONTH',
	DAY = 'DAY',
}

export enum SubscriptionStatus {
	TRIAL = 'TRIAL',
	SUBSCRIBED = 'SUBSCRIBED',
	BLOCKED = 'BLOCKED',
	CANCELLED = 'CANCELLED',
}

export enum AssignmentMode {
	SECTION = 'SECTION',
	ROOM = 'ROOM',
}

export enum LocationType {
	ROOM = 'ROOM',
	OTHER = 'OTHER',
}

export enum AssignmentRoleDisplayNameFontStyle {
	DEFAULT = 'DEFAULT',
	USER_LIST_CARDS = 'USER_LIST_CARDS',
	USER_LIST_TABLE = 'USER_LIST_TABLE',
}

export enum DistributionMode {
	BY_AMOUNT = 'BY_AMOUNT',
	BY_TIME = 'BY_TIME',
}

export enum CleaningMethod {
	STAY = 'minutesForStay',
	LONGSTAY = 'minutesForLongStay',
	LEAVE = 'minutesForLeave',
	ARRIVAL = 'minutesForArrival',
}

export enum AssignmentColumnType {
	ASSIGNABLE_ROOMS = 'ASSIGNABLE_ROOMS',
	ASSIGNED_TO_USER = 'ASSIGNED_TO_USER',
}

export enum AlertSeverity {
	ERROR = 'error',
	INFO = 'info',
	SUCCESS = 'success',
	WARNING = 'warning',
}

export enum PasswordMode {
	FORM = 'FORM',
	SUCCESS = 'SUCCESS',
	NOT_FOUND = 'NOT_FOUND',
}

export enum ExportFile {
	EXCEL = 'EXCEL',
}

export enum RegionKey {
	SYD = 'SYD',
	MEL = 'MEL',
	GRU = 'GRU',
	VCP = 'VCP',
	YUL = 'YUL',
	YYZ = 'YYZ',
	SCL = 'SCL',
	CDG = 'CDG',
	MRS = 'MRS',
	FRA = 'FRA',
	HYD = 'HYD',
	BOM = 'BOM',
	MTZ = 'MTZ',
	LIN = 'LIN',
	KIX = 'KIX',
	NRT = 'NRT',
	QRO = 'QRO',
	AMS = 'AMS',
	JED = 'JED',
	SIN = 'SIN',
	JNB = 'JNB',
	ICN = 'ICN',
	YNY = 'YNY',
	MAD = 'MAD',
	ARN = 'ARN',
	ZRH = 'ZRH',
	AUH = 'AUH',
	DXB = 'DXB',
	LHR = 'LHR',
	CWL = 'CWL',
	IAD = 'IAD',
	PHX = 'PHX',
	SJC = 'SJC',
}

export enum OhipEnvironment {
	PRODUCTION = 'PRODUCTION',
	NON_PRODUCTION = 'NON_PRODUCTION',
}

export enum OhipClientAndIntegration {
	CLIENT_ID = 'clientId',
	CLIENT_SECRET = 'clientSecret',
	INTEGRATION_USER_ID = 'integrationUserId',
	INTEGRATION_USER_KEY = 'integrationUserKey',
}

export enum RedCardChange {
	SET = 'SET',
	REMOVE = 'REMOVE',
}

export enum RedCardFilterEnum {
	ONLY_RED_CARD = 'ONLY_RED_CARD',
	NO_RED_CARD = 'NO_RED_CARD',
}

export enum ReportsView {
	LOGS = 'LOGS',
	ANALYSIS = 'ANALYSIS',
	GREEN_CHOICE = 'GREEN_CHOICE',
}

export enum RoomStateName {
	CLEAN = 'CLEAN',
	STAY = 'STAY',
	CHECK_PENDING = 'CHECK_PENDING',
	LEAVE = 'LEAVE',
	NOT_AVAILABLE = 'NOT_AVAILABLE',
	EXPEDITED_CLEANING = 'EXPEDITED_CLEANING',
	DISABLED = 'DISABLED',
	GREEN_CHOICE = 'GREEN_CHOICE',
}

export enum GreenChoiceStatus {
	AVAILABLE = 'AVAILABLE',
	ACTIVE = 'ACTIVE',
}

export enum GreenChoicePageState {
	ACTIVATION = 'ACTIVATION',
	SUCCESS = 'SUCCESS',
	ACTIVATION_IMPOSSIBLE = 'ACTIVATION_IMPOSSIBLE',
	LOADING = 'LOADING',
	ERROR = 'ERROR',
}

export enum MaximumDate {
	TODAY = 'TODAY',
	TOMORROW = 'TOMORROW',
}

export enum ResolvedLanguage {
	EN = 'en',
	DE = 'de',
}

export enum LanguageName {
	DEUTSCH = 'Deutsch',
	ENGLISH = 'English',
}

export enum GreenChoiceDescriptionOption {
	GUEST = 'GUEST',
	GUEST_BOTTOM_TEXT = 'GUEST_BOTTOM_TEXT',
	VOUCHER1 = 'VOUCHER1',
	VOUCHER2 = 'VOUCHER2',
	VOUCHER_LABEL = 'VOUCHER_LABEL',
	VOUCHER_LABEL_CUSTOM_1 = 'VOUCHER_LABEL_CUSTOM_1',
}

export enum GreenChoiceDescriptionView {
	GUEST_PAGE = 'GUEST_PAGE',
	VOUCHER = 'VOUCHER',
}

export enum GreenChoiceCutoffMode {
	TODAY = 'TODAY',
	TOMORROW = 'TOMORROW',
}

export enum GreenChoiceIncentiveType {
	VOUCHER_DIGITAL = 'VOUCHER_DIGITAL',
	VOUCHER_PHYSICAL = 'VOUCHER_PHYSICAL',
	DONATION = 'DONATION',
	CUSTOM_1 = 'CUSTOM_1',
	NONE = 'NONE',
}

export interface GreenChoiceIncentiveTypeEditor {
	type: GreenChoiceIncentiveType;
	setter?: React.Dispatch<React.SetStateAction<string>> | null;
	text?: string | null;
}

export enum LinenChangeState {
	NONE = 'NONE',
	TODAY = 'TODAY',
	LATE = 'LATE',
}

export enum AnalysisRowValue {
	TOTAL_CLEANING = 'TOTAL_CLEANING',
	CONFIRMED_TOTAL = 'CONFIRMED_TOTAL',
	CONFIRMED_STAYING = 'CONFIRMED_STAYING',
	CONFIRMED_LEAVING = 'CONFIRMED_LEAVING',
	UNCONFIRMED_TOTAL = 'UNCONFIRMED_TOTAL',
	UNCONFIRMED_STAYING = 'UNCONFIRMED_STAYING',
	UNCONFIRMED_LEAVING = 'UNCONFIRMED_LEAVING',
	ADDITIONAL_CLEANING = 'ADDITIONAL_CLEANING',
	RED_CARD_TOTAL = 'RED_CARD_TOTAL',
	RED_CARD_STAYING = 'RED_CARD_STAYING',
	RED_CARD_LEAVING = 'RED_CARD_LEAVING',
	CHECKED_TOTAL = 'CHECKED_TOTAL',
	CHECKED_CLEAN = 'CHECKED_CLEAN',
	CHECKED_DIRTY = 'CHECKED_DIRTY',
	RED_CARD_UNCHECKED = 'RED_CARD_UNCHECKED',
	LATE = 'LATE',
}

export enum TaskTriggerMode {
	COMMENT_EXISTS = 'COMMENT_EXISTS',
	EXCEEDS_N_GUESTS = 'EXCEEDS_N_GUESTS',
}

export enum TaskType {
	COMMENT = 'COMMENT',
	EXTRA_BED = 'EXTRA_BED'
}
