import * as React from 'react';
import {
	useContext,
	useMemo,
	useState
} from 'react';
import {
	FlagUtil,
	UserUtil
} from '@app/util';
import {
	AlertSeverity,
	Flag,
	GreenChoiceIncentiveType,
	User
} from '@app/model';
import {useTranslation} from 'react-i18next';
import {
	GreenChoiceMiscSettingsContext,
	GreenChoiceMiscSettingsContextValue,
	UserContext
} from '@app/context';

interface GreenChoiceMiscSettingsContextProviderProps {
	children: React.ReactNode;
}

export function GreenChoiceMiscSettingsContextProvider(
	props: GreenChoiceMiscSettingsContextProviderProps
) {
	const {children} = props;
	const {t} = useTranslation(['greenChoice', 'tenantAdmin']);
	const userContext = useContext(UserContext);
	const user = UserUtil.getUserObjectFromStorage()

	const [isClickATreeSwitchOn, setIsClickATreeSwitchOn] = useState<boolean>(
		FlagUtil.hasEnabledFlag(user, Flag.GREEN_CHOICE_CLICK_A_TREE)
	);
	const [isNoIncentiveSwitchOn, setIsNoIncentiveSwitchOn] = useState<boolean>(
		FlagUtil.hasEnabledFlag(user, Flag.GREEN_CHOICE_NO_INCENTIVE)
	);
	const [isCustom1SwitchOn, setIsCustom1SwitchOn] = useState<boolean>(
		FlagUtil.hasEnabledFlag(user, Flag.GREEN_CHOICE_CUSTOM_1)
	);
	const [isVoucherSwitchOn, setIsVoucherSwitchOn] = useState<boolean>(
		FlagUtil.hasEnabledFlag(user, Flag.GREEN_CHOICE_DIGITAL_VOUCHER) ||
		FlagUtil.hasEnabledFlag(user, Flag.GREEN_CHOICE_PHYSICAL_VOUCHER)
	);
	const [selectedIncentiveType, setSelectedIncentiveType] = useState<GreenChoiceIncentiveType>(
		FlagUtil.hasEnabledFlag(user, Flag.GREEN_CHOICE_DIGITAL_VOUCHER)
			? GreenChoiceIncentiveType.VOUCHER_DIGITAL
			: GreenChoiceIncentiveType.VOUCHER_PHYSICAL
	);

	function updateSwitchState(flag: Flag, newState: boolean) {
		switch (flag) {
			case Flag.GREEN_CHOICE_CLICK_A_TREE:
				setIsClickATreeSwitchOn(newState);
				break;
			case Flag.GREEN_CHOICE_NO_INCENTIVE:
				setIsNoIncentiveSwitchOn(newState);
				break;
			case Flag.GREEN_CHOICE_CUSTOM_1:
				setIsCustom1SwitchOn(newState);
				break;
			case Flag.GREEN_CHOICE_DIGITAL_VOUCHER:
			case Flag.GREEN_CHOICE_PHYSICAL_VOUCHER:
				setIsVoucherSwitchOn(newState);
		}
	}

	async function handleSwitchChange(
		flag: Flag,
		isFlagOn: boolean,
		showMessage: (messageToShow: string, severity: AlertSeverity) => void
	) {
		updateSwitchState(flag, !isFlagOn);
		await handleFlagChange(flag, isFlagOn, showMessage);
	}

	async function handleFlagChange(
		flag: Flag,
		isFlagOn: boolean,
		showMessage: (messageToShow: string, severity: AlertSeverity) => void
	): Promise<User | null> {
		const newUser = await FlagUtil.handleFlagSwitchChange(isFlagOn, flag);
		if (newUser) {
			userContext.setLoggedInUser(newUser);
			UserUtil.saveUpdatedUserToSessionStorage(newUser);
		} else {
			showMessage(t('tenantAdmin:app.snackbar.settingsUpdateError'), AlertSeverity.ERROR);
			updateSwitchState(
				flag,
				FlagUtil.hasEnabledFlag(user, flag)
			);
		}

		return newUser;
	}

	const value = useMemo(
		(): GreenChoiceMiscSettingsContextValue => ({
			isClickATreeSwitchOn: isClickATreeSwitchOn,
			isVoucherSwitchOn: isVoucherSwitchOn,
			selectedIncentiveType: selectedIncentiveType,
			isNoIncentiveSwitchOn: isNoIncentiveSwitchOn,
			isCustom1SwitchOn: isCustom1SwitchOn,
			handleSwitchChange: handleSwitchChange,
			handleFlagChange: handleFlagChange,
			setIsVoucherSwitchOn: setIsVoucherSwitchOn,
			setSelectedIncentiveType: setSelectedIncentiveType,
			setIsClickATreeSwitchOn: setIsClickATreeSwitchOn,
			setIsNoIncentiveSwitchOn: setIsNoIncentiveSwitchOn,
			setIsCustom1SwitchOn: setIsCustom1SwitchOn,
		}),
		[
			isClickATreeSwitchOn,
			isVoucherSwitchOn,
			selectedIncentiveType,
			isNoIncentiveSwitchOn,
			isCustom1SwitchOn,
			handleSwitchChange,
			handleFlagChange,
			setIsVoucherSwitchOn,
			setSelectedIncentiveType,
			setIsClickATreeSwitchOn,
			setIsNoIncentiveSwitchOn,
			setIsCustom1SwitchOn,
		]
	);

	return (
		<GreenChoiceMiscSettingsContext.Provider value={value}>
			{children}
		</GreenChoiceMiscSettingsContext.Provider>
	);
}
