import React, {useContext} from 'react';
import {Box} from '@mui/material';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';
import {ActivationPageValues} from '../../GreenChoiceGuest';
import {GreenChoiceIncentiveType, ResolvedLanguage} from '@app/model';
import {BubbleEditorWithOutline} from '../../../settings/green-choice-custom-description-card/bubble-editor-with-outline/BubbleEditorWithOutline';
import {GreenChoiceLastNameAndIncentiveTypeForm} from '../../last-name-form/GreenChoiceLastNameAndIncentiveTypeForm';
import {GreenChoiceMiscSettingsContext} from '@app/context';
import {GreenChoiceIncentiveTypeEditor} from "../../../../../model";

interface GreenChoiceActivationPageProps {
	isEditMode: boolean;
	data: {
		description: string;
		bottomText: string | null;
		voucherLabel: string | null;
		voucherLabelCustom1: string | null;
	};
	language: ResolvedLanguage;
	date: Date;
	updateGreenChoiceData: (values: ActivationPageValues) => void;
	isSubmitButtonLoading: boolean;
	actions?: {
		setGuestDescriptionEdited: React.Dispatch<React.SetStateAction<string>>;
		setBottomTextEdited: React.Dispatch<React.SetStateAction<string>>;
		setVoucherLabelEdited: React.Dispatch<React.SetStateAction<string>>;
		setVoucherLabelCustom1Edited: React.Dispatch<React.SetStateAction<string>>;
	};
}

export function GreenChoiceActivationPage(props: GreenChoiceActivationPageProps) {
	const {
		date,
		data,
		language,
		updateGreenChoiceData,
		isSubmitButtonLoading,
		isEditMode,
		actions,
	} = props;
	const {t} = useTranslation(['greenChoice', 'common']);

	const miscSettingsContext = useContext(GreenChoiceMiscSettingsContext);

	const activationPageSchema = getActiveIncentiveTypes().length > 1 ? yup.object({
		lastName: yup
			.string()
			.trim()
			.required(
				t('greenChoice:guestView.activationPage.validation.lastName', {lng: language})
			),
		incentiveType: yup.mixed<GreenChoiceIncentiveType>().required(t('common:validation.empty')),
	}) : yup.object({
		lastName: yup
			.string()
			.trim()
			.required(
				t('greenChoice:guestView.activationPage.validation.lastName', {lng: language})
			)});

	function getActiveIncentiveTypes() {
		const activeIncentiveTypes: GreenChoiceIncentiveTypeEditor[] = [];
		if (miscSettingsContext.isClickATreeSwitchOn) {
			activeIncentiveTypes.push({
				type: GreenChoiceIncentiveType.DONATION,
			});
		}
		if (miscSettingsContext.isNoIncentiveSwitchOn) {
			activeIncentiveTypes.push({
				type: GreenChoiceIncentiveType.NONE,
			});
		}
		if (
			miscSettingsContext.isVoucherSwitchOn &&
			miscSettingsContext.selectedIncentiveType === GreenChoiceIncentiveType.VOUCHER_PHYSICAL
		) {
			activeIncentiveTypes.push({
				type: GreenChoiceIncentiveType.VOUCHER_PHYSICAL,
				setter: actions?.setVoucherLabelEdited,
				text: data.voucherLabel,
			});
		}
		if (
			miscSettingsContext.isVoucherSwitchOn &&
			miscSettingsContext.selectedIncentiveType === GreenChoiceIncentiveType.VOUCHER_DIGITAL
		) {
			activeIncentiveTypes.push({
				type: GreenChoiceIncentiveType.VOUCHER_DIGITAL,
				setter: actions?.setVoucherLabelEdited,
				text: data.voucherLabel,
			});
		}
		if (
			miscSettingsContext.isCustom1SwitchOn &&
			miscSettingsContext.selectedIncentiveType === GreenChoiceIncentiveType.CUSTOM_1
		) {
			activeIncentiveTypes.push({
				type: GreenChoiceIncentiveType.CUSTOM_1,
				setter: actions?.setVoucherLabelCustom1Edited,
				text: data.voucherLabelCustom1,
			});
		}

		return activeIncentiveTypes;
	}

	function renderInfoTextOrEditor() {
		return (
			<>
				{isEditMode ? (
					<Box sx={{mt: 5, mb: 3}}>
						<BubbleEditorWithOutline
							initialValue={data.description}
							onChange={(value: string) => actions?.setGuestDescriptionEdited(value)}
						/>
					</Box>
				) : (
					<Box sx={{mt: 5, mb: 3, textAlign: 'left'}}>
						<div
							dangerouslySetInnerHTML={{
								__html: data.description,
							}}
						/>
					</Box>
				)}
			</>
		);
	}

	function renderBottomText() {
		return (
			<>
				{isEditMode ? (
					<>
						<BubbleEditorWithOutline
							initialValue={data.bottomText ? data.bottomText : ''}
							onChange={(value: string) => actions?.setBottomTextEdited(value)}
						/>
					</>
				) : (
					<Box sx={{textAlign: 'left'}}>
						<div
							dangerouslySetInnerHTML={{
								__html: data.bottomText ? data.bottomText : '',
							}}
						/>
					</Box>
				)}
			</>
		);
	}

	return (
		<Box sx={{mt: 5}}>
			<GreenChoiceLastNameAndIncentiveTypeForm
				date={date}
				activeIncentiveTypes={getActiveIncentiveTypes()}
				onSubmit={updateGreenChoiceData}
				isSubmitButtonLoading={isSubmitButtonLoading}
				shouldDisableFields={isEditMode}
				isEditMode={isEditMode}
				validationSchema={activationPageSchema}
				language={language}
				infoText={renderInfoTextOrEditor()}
				bottomText={renderBottomText()}
			/>
		</Box>
	);
}
