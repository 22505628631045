import React from 'react';
import {ActivationPageValues} from '../../../../guest/GreenChoiceGuest';
import {GreenChoiceActivationPage} from '../../../../guest/pages/activation-page/GreenChoiceActivationPage';
import {GreenChoicePublicHeader} from '../../../../guest/header/GreenChoicePublicHeader';
import '../CustomDescriptionStyles.css';
import {LanguageName} from '@app/model';
import {StringUtil} from '@app/util';
import {Box} from '@mui/material';

interface GuestPageDescriptionTabProps {
	bottomText: string;
	description: string;
	voucherLabel: string;
	voucherLabelCustom1: string;
	selectedLanguage: LanguageName;
	tenantName: string;
	setDescriptionEdited: React.Dispatch<React.SetStateAction<string>>;
	setBottomTextEdited: React.Dispatch<React.SetStateAction<string>>;
	setVoucherLabelEdited: React.Dispatch<React.SetStateAction<string>>;
	setVoucherLabelCustom1Edited: React.Dispatch<React.SetStateAction<string>>;
}

export function GuestPageDescriptionTab(props: GuestPageDescriptionTabProps) {
	const {
		selectedLanguage,
		setBottomTextEdited,
		setDescriptionEdited,
		setVoucherLabelEdited,
		setVoucherLabelCustom1Edited,
		bottomText,
		tenantName,
		description,
		voucherLabel,
		voucherLabelCustom1,
	} = props;

	function updateGreenChoiceData(_lastname: ActivationPageValues) {
		//do nothing
	}

	return (
		<Box p={5}>
			<GreenChoicePublicHeader title={tenantName} subtitle="01" />
			<GreenChoiceActivationPage
				data={{description: description, bottomText: bottomText, voucherLabel: voucherLabel, voucherLabelCustom1: voucherLabelCustom1}}
				actions={{
					setGuestDescriptionEdited: setDescriptionEdited,
					setBottomTextEdited: setBottomTextEdited,
					setVoucherLabelEdited: setVoucherLabelEdited,
					setVoucherLabelCustom1Edited: setVoucherLabelCustom1Edited,
				}}
				language={StringUtil.mapResolvedLanguage(selectedLanguage)}
				isEditMode={true}
				isSubmitButtonLoading={false}
				updateGreenChoiceData={updateGreenChoiceData}
				date={new Date()}
			/>
		</Box>
	);
}
