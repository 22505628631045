import React, {
	useCallback,
	useContext,
	useEffect,
	useState
} from 'react';
import {
	Box,
	CircularProgress,
	Container,
	Divider,
	FormControl,
	MenuItem,
	Select,
	SelectChangeEvent,
	Typography,
	useTheme,
} from '@mui/material';
import {GreenChoiceService} from '@app/services';
import {
	ActiveGreenChoiceData,
	GreenChoiceGuestData,
	GreenChoiceGuestDescription,
	GreenChoiceIncentiveType,
	GreenChoiceOption,
	GreenChoicePageState,
	GreenChoiceStatus,
	LanguageName,
	ResolvedLanguage,
} from '@app/model';
import {useTranslation} from 'react-i18next';
import {GreenChoiceActivationPage} from './pages/activation-page/GreenChoiceActivationPage';
import {GreenChoiceSuccessPage} from './pages/success-page/GreenChoiceSuccessPage';
import {GreenChoiceActivationImpossiblePage} from './pages/activation-impossible-page/GreenChoiceActivationImpossiblePage';
import {GreenChoiceErrorPage} from './pages/error-page/GreenChoiceErrorPage';
import {GreenChoicePublicHeader} from './header/GreenChoicePublicHeader';
import * as DOMPurify from 'dompurify';
import {StringUtil} from '@app/util';
import {Link} from 'react-router-dom';
import {ClickATreeLink} from './click-a-tree-link/ClickATreeLink';
import {GreenChoiceMiscSettingsContext} from '@app/context';

export interface ActivationPageValues {
	lastName: string;
	incentiveType: GreenChoiceIncentiveType | undefined;
}

export function GreenChoiceGuest() {
	const {t, i18n} = useTranslation(['greenChoice', 'common']);
	const theme = useTheme();

	const [pageState, setPageState] = useState<GreenChoicePageState>(GreenChoicePageState.LOADING);
	const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState<boolean>(false);
	const [greenChoiceData, setGreenChoiceData] = useState<GreenChoiceGuestData | undefined>(
		undefined
	);
	const [isDescriptionLoading, setIsDescriptionLoading] = useState<boolean>(true);
	const [description, setDescription] = useState<string>('');
	const [bottomText, setBottomText] = useState<string | null>(null);
	const [voucherLabel, setVoucherLabel] = useState<string | null>(null);
	const [voucherLabelCustom1, setVoucherLabelCustom1] = useState<string | null>(null);
	const [isSuccessViewLocked, setIsSuccessViewLocked] = useState<boolean>(true);
	const [selectedLanguage, setSelectedLanguage] = useState<ResolvedLanguage>(
		StringUtil.mapResolvedLanguage(i18n.language)
	);
	const [activeGreenChoiceData, setActiveGreenChoiceData] = useState<
		ActiveGreenChoiceData | undefined
	>(undefined);

	const greenChoiceInfo = window.location.pathname.split('/').slice(3);
	const hotelId = greenChoiceInfo[0];
	const id = greenChoiceInfo[1];

	const greenChoiceService = GreenChoiceService.get();
	const miscSettingsContext = useContext(GreenChoiceMiscSettingsContext);

	function updateContextActiveIncentives(data: GreenChoiceGuestData) {
		console.log("data: " + JSON.stringify(data, null, 4));

		miscSettingsContext.setIsClickATreeSwitchOn(data.clickATreeActive);
		miscSettingsContext.setIsNoIncentiveSwitchOn(data.noIncentiveActive);
		miscSettingsContext.setIsVoucherSwitchOn(
			data.physicalVoucherActive || data.digitalVoucherActive
		);
		miscSettingsContext.setSelectedIncentiveType(
			data.digitalVoucherActive
				? GreenChoiceIncentiveType.VOUCHER_DIGITAL
				: GreenChoiceIncentiveType.VOUCHER_PHYSICAL
		);
	}

	const getGreenChoiceData = useCallback(() => {
		greenChoiceService
			.getGreenChoiceGuestData(hotelId, id)
			.then((res: GreenChoiceGuestData) => {
				console.log("res: " + JSON.stringify(res, null, 4));
				setGreenChoiceData(res);
				updateContextActiveIncentives(res);
				getPageState(res.cleaningOptions);
			})
			.catch(() => {
				setPageState(GreenChoicePageState.ERROR);
			});
	}, [greenChoiceService]);

	const getGreenChoiceDescription = useCallback(
		(language: ResolvedLanguage) => {
			setIsDescriptionLoading(true);
			greenChoiceService
				.getGreenChoiceDescriptionPublic(hotelId, id, language)
				.then((res: GreenChoiceGuestDescription) => {
					if (res.description) {
						setDescription(DOMPurify.sanitize(res.description));
					} else {
						const defaultDescription = t(
							'greenChoice:guestView.activationPage.defaultDescription',
							{
								lng: language,
							}
						);
						setDescription(`<p>${defaultDescription}</p>`);
					}
					setBottomText(res.bottomText ? DOMPurify.sanitize(res.bottomText) : null);
					setVoucherLabel(res.voucherLabel ? DOMPurify.sanitize(res.voucherLabel) : null);
					setVoucherLabelCustom1(res.voucherLabelCustom1 ? DOMPurify.sanitize(res.voucherLabelCustom1) : null);
					setIsDescriptionLoading(false);
				})
				.catch(() => {
					setPageState(GreenChoicePageState.ERROR);
					setIsDescriptionLoading(false);
				});
		},
		[greenChoiceService]
	);

	useEffect(() => {
		getGreenChoiceData();
		getGreenChoiceDescription(StringUtil.mapResolvedLanguage(selectedLanguage));
	}, [getGreenChoiceData, selectedLanguage]);

	function getPageState(cleaningOptions: GreenChoiceOption[]) {
		if (cleaningOptions) {
			if (cleaningOptions.length === 0) {
				setPageState(GreenChoicePageState.ACTIVATION_IMPOSSIBLE);
				return;
			}

			switch (cleaningOptions[0].status) {
				case GreenChoiceStatus.AVAILABLE:
					setPageState(GreenChoicePageState.ACTIVATION);
					return;
				case GreenChoiceStatus.ACTIVE:
					setPageState(GreenChoicePageState.SUCCESS);
					return;
			}
		}
		setPageState(GreenChoicePageState.ACTIVATION_IMPOSSIBLE);
	}

	function updateGreenChoice(values: ActivationPageValues) {
		setIsSubmitButtonLoading(true);
		greenChoiceService
			.createGreenChoicePublic(hotelId, id, values.lastName.trim(), values.incentiveType)
			.then((res: GreenChoiceGuestData) => {
				setIsSuccessViewLocked(false);
				getPageState(res.cleaningOptions);
				setGreenChoiceData(res);
				updateContextActiveIncentives(res);
				setIsSubmitButtonLoading(false);
			})
			.catch(() => {
				setPageState(GreenChoicePageState.ERROR);
				setIsSubmitButtonLoading(false);
			});
	}

	function renderView() {
		if (greenChoiceData) {
			switch (pageState) {
				case GreenChoicePageState.LOADING:
					return <CircularProgress/>;
				case GreenChoicePageState.ACTIVATION:
					return (
						<GreenChoiceActivationPage
							language={selectedLanguage}
							data={{description: description, bottomText: bottomText, voucherLabel: voucherLabel, voucherLabelCustom1: voucherLabelCustom1}}
							isEditMode={false}
							isSubmitButtonLoading={isSubmitButtonLoading}
							date={greenChoiceData.cleaningOptions[0].date}
							updateGreenChoiceData={updateGreenChoice}
						/>
					);
				case GreenChoicePageState.SUCCESS:
					return (
						<GreenChoiceSuccessPage
							activeGreenChoiceData={activeGreenChoiceData}
							setActiveGreenChoiceData={setActiveGreenChoiceData}
							language={selectedLanguage}
							isViewLocked={isSuccessViewLocked}
							setIsViewLocked={setIsSuccessViewLocked}
							greenChoiceData={greenChoiceData}
							hotelId={hotelId}
							id={id}
						/>
					);
				case GreenChoicePageState.ACTIVATION_IMPOSSIBLE:
					return (
						<GreenChoiceActivationImpossiblePage
							language={selectedLanguage}
							data-testid="green-choice-activation-page"
						/>
					);
				case GreenChoicePageState.ERROR:
					return <GreenChoiceErrorPage language={selectedLanguage}/>;
			}
		}

		if (pageState === GreenChoicePageState.ERROR) {
			return <GreenChoiceErrorPage language={selectedLanguage}/>;
		} else {
			return <CircularProgress/>;
		}
	}

	return isDescriptionLoading ? (
		<CircularProgress/>
	) : (
		<Container maxWidth="sm" sx={{pl: 4, pr: 4, pt: 5}}>
			<Box
				sx={{
					position: 'relative',
					minHeight: '102vh',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				{greenChoiceData &&
				((pageState === GreenChoicePageState.SUCCESS &&
						(activeGreenChoiceData?.incentiveType !==
							GreenChoiceIncentiveType.VOUCHER_DIGITAL ||
							isSuccessViewLocked)) ||
					pageState !== GreenChoicePageState.SUCCESS) ? (
					<GreenChoicePublicHeader
						title={greenChoiceData.hotelName}
						subtitle={greenChoiceData.roomLabel}
					/>
				) : null}
				<Box
					sx={{
						display: 'flex',
						flexGrow: 2,
						flexDirection: 'column',
					}}
				>
					{renderView()}
				</Box>
				<Box mt={6}>
					<ClickATreeLink
						isClickATreeActive={!!greenChoiceData?.clickATreeActive}
						pageState={pageState}
						language={selectedLanguage}
					/>
					<Divider sx={{mt: 2, mb: 2}}/>
					<Box
						sx={{
							height: '40px',
							width: '100%',
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'end',
						}}
					>
						<FormControl variant="standard" sx={{minWidth: 120}}>
							<Select
								value={selectedLanguage}
								onChange={(event: SelectChangeEvent<ResolvedLanguage>) => {
									setSelectedLanguage(event.target.value as ResolvedLanguage);
									getGreenChoiceDescription(
										StringUtil.mapResolvedLanguage(event.target.value)
									);
								}}
							>
								<MenuItem value={ResolvedLanguage.EN}>
									{LanguageName.ENGLISH}
								</MenuItem>
								<MenuItem value={ResolvedLanguage.DE}>
									{LanguageName.DEUTSCH}
								</MenuItem>
							</Select>
						</FormControl>
						<Box>
							<Typography align="right">
								{t('greenChoice:guestView.poweredBy', {
									lng: selectedLanguage,
								})}
							</Typography>
						</Box>
					</Box>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'flex-end',
							my: 2,
						}}
					>
						<Box sx={{mr: 2}}>
							<Link
								to="/imprint"
								style={{
									textDecoration: 'none',
									color: theme.palette.text.disabled,
									fontSize: 'small',
								}}
							>
								{t('common:imprint', {
									lng: selectedLanguage,
								})}
							</Link>
						</Box>
						<Box>
							<Link
								to="/privacy"
								style={{
									textDecoration: 'none',
									color: theme.palette.text.disabled,
									fontSize: 'small',
								}}
							>
								{t('common:privacyNotice', {
									lng: selectedLanguage,
								})}
							</Link>
						</Box>
					</Box>
				</Box>
			</Box>
		</Container>
	);
}
