import {SnackbarContext} from '@app/context';
import {GreenChoiceService} from '@app/services';
import React, {useContext, useState} from 'react';
import {
	GreenChoiceLastNameAndIncentiveTypeForm,
	GreenChoiceLastNameFormValues,
} from '../../last-name-form/GreenChoiceLastNameAndIncentiveTypeForm';
import {AlertSeverity, GreenChoiceIncentiveType, ResolvedLanguage} from '@app/model';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';
import {FormikHelpers} from 'formik';
import {Typography} from '@mui/material';

interface GreenChoiceLockedPageProps {
	date: Date;
	hotelId: string;
	uuid: string;
	setIsViewLocked: React.Dispatch<React.SetStateAction<boolean>>;
	language: ResolvedLanguage;
}

export function GreenChoiceLockedPage(props: GreenChoiceLockedPageProps) {
	const {hotelId, uuid, date, setIsViewLocked, language} = props;
	const {t} = useTranslation(['common', 'greenChoice']);

	const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState<boolean>(false);

	const greenChoiceService = GreenChoiceService.get();
	const {showMessage} = useContext(SnackbarContext);

	const validationSchema = yup.object({
		lastName: yup.string().required(t('common:validation.empty', {lng: language})),
	});

	function onSubmit(
		values: GreenChoiceLastNameFormValues,
		actions: FormikHelpers<GreenChoiceLastNameFormValues>
	) {
		setIsSubmitButtonLoading(true);
		greenChoiceService
			.verifyLastName(values.lastName, date, hotelId, uuid)
			.then((res: boolean) => {
				if (res) {
					setIsViewLocked(false);
					setIsSubmitButtonLoading(false);
				} else {
					actions.setFieldError(
						'lastName',
						t('greenChoice:guestView.lockedPage.lastName.error', {lng: language})
					);
					setIsSubmitButtonLoading(false);
				}
			})
			.catch(() => {
				showMessage(t('common:error.commonError', {lng: language}), AlertSeverity.ERROR);
				setIsSubmitButtonLoading(false);
			});
	}

	return (
		<>
			<Typography align="left" sx={{mt: 15}}>
				{t('greenChoice:guestView.lockedPage.lastName.explanation', {lng: language})}
			</Typography>
			<GreenChoiceLastNameAndIncentiveTypeForm
				activeIncentiveTypes={[{type: GreenChoiceIncentiveType.NONE}]}
				date={date}
				language={language}
				onSubmit={onSubmit}
				isSubmitButtonLoading={isSubmitButtonLoading}
				shouldDisableFields={false}
				validationSchema={validationSchema}
				isEditMode={false}
				isLockedPage={true}
			/>
		</>
	);
}
